<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-checkbox
              label="Nouveau"
              v-model="newproduct"
              dense
              @change="newproduct_click"
              v-if="!pfm.cons_id"
            ></v-checkbox>
            <v-spacer> </v-spacer>
            <v-radio-group v-model="service" @change="cs++" row mandatory dense>
              <v-radio label="Produits" :value="0"></v-radio>
              <v-radio label="Service" :value="1"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="10" v-if="newproduct">
                  <v-text-field
                    autocomplete="off"
                    ref="label"
                    dense
                    v-model="editedItem.label"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="10" v-if="!newproduct">
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    :whereadd="whereadd"
                    :list_items="tiertype == 2 ? products_list : null"
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="!newproduct && tiertype == 2 && pfm.tier_id"
                >
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="'id'"
                    :items="articles"
                    :filter="customFilter"
                    label="Article"
                    dense
                    clearable
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code_article ? item.code_article + "-" : "") +
                        item.article_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.code_article ? item.code_article + '-' : '') +
                            item.article_name
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="pfm.tier_id && !newproduct && spec && tiertype == 1"
                >
                  <v-checkbox
                    label="Tous"
                    v-model="allproduct"
                    @change="cs++"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    ref="pu"
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pu"
                    :readonly="!edit_price"
                    :label="
                      'Prix ' + (pfm.devise ? '(' + pfm.devise + ')' : '')
                    "
                    :rules="[(v) => !!v || v == 0 || 'Prix obligatoire']"
                    @keydown.enter="$refs.qte.focus()"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="'Quantité'"
                    :rules="[
                      (v) => !!v || 'Quantité obligatoire',
                      (v) => !v || v > 0 || 'Valeur incorrecte',
                    ]"
                    @input="qte_change"
                    @keydown.enter="save"
                    :disabled="save_disable"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    :hint="
                      editedItem.fk_unit != editedItem.fk_unit_product &&
                      editedItem.qte2
                        ? editedItem.qte2 + ' ' + editedItem.unit
                        : ''
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-select
                    ref="unite"
                    :items="units_list"
                    v-model="editedItem.fk_unit"
                    item-text="name"
                    item-value="id"
                    label="Unité de Mesure"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <v-text-field
                    autocomplete="off"
                    ref="tva"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    label="TVA"
                    :readonly="
                      tiertype == 1
                        ? readonly ||
                          !(
                            $store.state.auth.includes('02027') ||
                            $store.state.isadmin ||
                            editedItem.produit_id == null
                          )
                        : readonly
                    "
                    :rules="[(v) => !v || v > 0 || 'Valeur incorrecte']"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="delai"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.delai"
                    label="Delai (jours)"
                    :rules="[
                      (v) =>
                        !v ||
                        (v > 0 &&
                          (pfm.delai
                            ? verif_date(pfm.date_pfm, v, pfm.date_limite)
                            : true)) ||
                        'Date Max ' + datefr(pfm.date_limite),
                    ]"
                    hide-spin-buttons
                    :hint="
                      pfm.date_limite
                        ? 'Delai Liv. : ' + datefr(pfm.date_limite)
                        : ''
                    "
                    :readonly="readonly"
                    @input="addDays"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  {{ duedate ? "Avant le : " + duedate : "" }}
                </v-col>
                <v-col cols="12" sm="1" md="3">
                  <v-select
                    ref="adresse"
                    :items="adresses"
                    v-model="editedItem.adress_id"
                    :item-text="
                      (item) =>
                        item.label +
                        '-' +
                        item.departement_name +
                        '-' +
                        item.ville_name
                    "
                    item-value="id"
                    label="Adresse de livraison"
                    :readonly="readonly"
                    dense
                    v-if="tiertype == 1"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1" md="12" v-if="tiertype == 1">
                  <v-text-field
                    autocomplete="off"
                    ref="description"
                    dense
                    v-model="editedItem.description"
                    label="Description Additionnelle"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="save_disable"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_PRODUIT_PFM from "../graphql/Proforma/CREATE_PRODUIT.gql";
import UPDATE_PRODUIT_PFM from "../graphql/Proforma/UPDATE_PRODUIT.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";

import { spec } from "print/data.js";
export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "pfmdetailform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    tiertype: Number,
    tier_type_prix: Array,
    units: Array,
    qte_prix: Array,
    pfm: Object,
    tier_products: Array,
  },

  data: () => ({
    service: 0,
    save_disable: false,
    newproduct: false,
    duedate: null,
    allproduct: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    template: 0,
    attributs: [],
    unfilter: false,
    prixvente: 0,
    prixachat: 0,
    produits: [],
    categorie_list: [],
    units_list: [],
    articles: [],
    pageInfo: null,
    label: null,
    produit_id: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
  }),

  computed: {
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    variableadd() {
      let w = {
        Kind: 3,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: [2],
      };
      return w;
    },
    whereadd() {
      let l = [{ column: "SERVICE", value: this.service }];
      if (this.tiertype == 1 && !this.allproduct)
        l.push({ column: "CLIENT_ID", value: this.pfm.tier_id });
      return l;
    },

    adresses() {
      return this.pfm ? this.pfm.adresses : [];
    },
    edit_price() {
      let ok = false;
      if (this.tiertype == 1 && !this.prixvente) ok = true;
      if (
        this.tiertype == 1 &&
        this.prixvente > 0 &&
        !this.readonly &&
        (this.$store.state.auth.includes("02027") ||
          this.$store.state.isadmin ||
          this.editedItem.produit_id == null)
      )
        ok = true;
      if (this.tiertype == 2 && !this.prixachat) ok = true;
      if (
        this.tiertype == 2 &&
        this.prixachat > 0 &&
        !this.readonly &&
        this.pfm.currency_id != 114
      )
        ok = true;

      return ok;
    },

    spec() {
      return spec;
    },

    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    products_list() {
      let list = [];
      let l = this.tiertype == 2 ? this.tier_products : [];

      l = l.filter(
        (elm) =>
          !this.items.map((elm2) => elm2.label).includes(elm.label) ||
          elm.label == this.editedItem.label
      );

      if (l)
        list = l.filter(
          (elm) => elm.service == this.service || elm.service == null
        );
      return list;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    console.log(this.units);
    this.units_list = this.units;
    if (this.tiertype == 1) this.allproduct = !this.spec || !this.pfm.tier_id;
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);

      if (this.editedItem.id > 0) {
        this.produit_id = this.editedItem.produit_id;
        this.cs++;
        if (!this.editedItem.produit_id) {
          this.newproduct = true;
          this.newproduct_click();
        }
        this.produit_change(this.editedItem.produit_id);
      } else {
        if (this.service == 1 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_service_tva;
        if (this.service == 0 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_product_tva;
      }
    }

    this.addDays();
  },

  methods: {
    newproduct_click() {
      if (this.newproduct) {
        this.editedItem.produit_id = null;

        this.units_list = this.units;
      } else {
        this.editedItem.label = null;
        this.editedItem.pu = null;
        this.editedItem.qte = null;
        this.editedItem.fk_unit = null;
        this.units_list = [];
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    verif_date(date1, days, date2) {
      var end = new Date(date1);
      end.setDate(end.getDate() + parseInt(days));
      var start = new Date(date2);
      return end <= start;
    },
    addDays() {
      if (this.editedItem.delai > 0) {
        let d = parseInt(this.editedItem.delai);
        var result = new Date(this.pfm.date_pfm);
        result.setDate(result.getDate() + d);
        this.duedate = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else this.duedate = null;
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    produit_change(item, l) {
      let i;
      let list = this.products_list;
      if (l) list = l;
      this.produit_id = item;
      let produit;
      if (this.produit_id) {
        if (list.length > 0) {
          i = list.findIndex((elm) => elm.id == this.produit_id);
          if (i >= 0) {
            this.editedItem.produit_id = l ? list[i].id : list[i].produit_id;
            //if (this.newproduct) this.editedItem.label = list[i].label;
            produit = list[i];

            this.editedItem.fk_unit_buy = produit.fk_unit_buy;
            this.editedItem.fk_unit_product = produit.fk_unit;
            if (this.tiertype == 1) {
              let k = this.tier_type_prix.findIndex(
                (elm) =>
                  elm.produit_id == this.editedItem.produit_id &&
                  elm.tier_type_id == this.item.tier_type_id
              );

              if (k >= 0) {
                this.prixvente = this.tier_type_prix[k].prix;
              } else this.prixvente = produit.prixvente;

              if (this.editedItem.id < 0) this.editedItem.pu = this.prixvente;
              //unite de mesure
              if (produit.fk_unit && this.editedItem.id < 0)
                this.editedItem.fk_unit = produit.fk_unit;
            }

            if (this.tiertype == 2) {
              if (this.editedItem.id < 0) {
                this.editedItem.dem_det_id = produit.dem_det_id;
              }
              this.editedItem.factor = produit.factor;
              this.prixachat = produit.prixachat;
              this.label_ext = produit.label_ext;
              this.ref_ext = produit.ref_ext;
              if (this.editedItem.id < 0) {
                this.editedItem.pu = this.prixachat;
              }
              if (produit.produit_id && this.pfm.tier_id) {
                let l = this.tier_products.filter(
                  (elm) => elm.produit_id == produit.produit_id
                );
                if (!this.pfm.cons_id) this.articles = l;
                else
                  this.articles = l[0].articles.filter(
                    (elm) => elm.tier_id == this.pfm.tier_id
                  );
                if (this.editedItem.id < 0 && this.articles.length == 1)
                  this.editedItem.article_id = this.articles[0].id;
              }
              //unite de mesure
              if (produit.fk_unit_buy && this.editedItem.id < 0)
                this.editedItem.fk_unit = produit.fk_unit_buy;
            }
            if (produit.tva_tx && this.editedItem.id < 0)
              this.editedItem.tva_tx = produit.tva_tx;

            this.get_units();

            this.editedItem.unit_name = produit.unit_name;

            if (!this.isProductClosed) {
              if (!this.editedItem.pu) this.$refs.pu.focus();
              else this.$refs.qte.focus();
            }
          } else if (this.editedItem.id > 0) {
            this.allproduct = true;
            this.produit_change();
          }
        } else this.units_list = this.units;
      }

      this.editedItem.tva_tx = this.$store.state.options[0].default_product_tva;
    },

    get_units() {
      let k = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_product
      );

      if (k >= 0) {
        let c = this.units[k].category_id;
        this.units_list = this.units.filter((elm) => elm.category_id == c);
      }
      k = this.units_list.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_buy
      );
      if (k == -1) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_buy
        );
        if (i >= 0)
          this.units_list.push({
            id: this.units[i].id,
            name: this.units[i].name,
          });
      }
    },

    OpenFilterForm() {
      this.isFilterClosed = false;
    },

    qte_change() {
      if (this.tiertype == 1) {
        let k = this.tier_type_prix.findIndex(
          (elm) =>
            elm.produit_id == this.editedItem.produit_id &&
            elm.tier_type_id == this.item.tier_type_id
        );

        if (k == -1) {
          let x = this.qte_prix.findIndex(
            (elm) =>
              elm.produit_id == this.editedItem.produit_id &&
              elm.qte_from <= this.editedItem.qte &&
              (elm.qte_to ? elm.qte_to >= this.editedItem.qte : true)
          );
          if (x >= 0) {
            if (this.qte_prix[x].prix > 0)
              this.editedItem.pu = this.qte_prix[x].prix;
          } else if (this.prixvente) this.editedItem.pu = this.prixvente;
        }
      }
      this.editedItem.qte2 = 0;
      if (this.editedItem.fk_unit != this.editedItem.fk_unit_product) {
        let i = this.units_list.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        let cat_buy;
        let cat_unit;
        if (i >= 0) cat_buy = this.units[i].category_id;
        let j = this.units_list.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_product
        );
        if (j >= 0) cat_unit = this.units[j].category_id;
        if (cat_buy == cat_unit) {
          let factor_unit_buy = this.units_list[i].factor
            ? this.units_list[i].factor
            : 1;
          let factor_unit_product = this.units_list[j].factor
            ? this.units_list[j].factor
            : 0;
          this.editedItem.factor = factor_unit_product / factor_unit_buy;
        }
        this.editedItem.qte2 = this.editedItem.qte * this.editedItem.factor;
        if (j >= 0) this.editedItem.unit = this.units_list[j].name;
      }
    },

    closeProductForm() {
      this.isProductClosed = true;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          r = data.data;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        let ok = true;
        if (ok) {
          this.save_disable = true;
          if (this.editedItem.id > 0) {
            v = {
              input: {
                id: this.editedItem.id,
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                dem_det_id: this.editedItem.dem_det_id,
                delai: parseInt(this.editedItem.delai),
                adress_id: this.editedItem.adress_id,
                description: this.editedItem.description,
                article_id: this.editedItem.article_id,
                categorie_id: this.editedItem.categorie_id,
                label: this.newproduct ? this.editedItem.label : null,

                tva_tx: parseFloat(this.editedItem.tva_tx),
                fk_unit: this.editedItem.fk_unit,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_PRODUIT_PFM, v);
            this.$emit("change", this.editedItem);
          } else {
            v = {
              input: {
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                dem_det_id: this.editedItem.dem_det_id,
                delai: parseInt(this.editedItem.delai),
                adress_id: this.editedItem.adress_id,

                description: this.editedItem.description,
                article_id: this.editedItem.article_id,
                categorie_id: this.editedItem.categorie_id,
                tva_tx: parseFloat(this.editedItem.tva_tx),
                fk_unit: this.editedItem.fk_unit,
                comment: this.editedItem.comment,
                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };

            v.input.label = this.editedItem.label;
            v.input.pfm_id = this.item.pfm_id;

            let r = null;
            r = await this.maj(CREATE_PRODUIT_PFM, v);

            if (r) {
              this.editedItem.id = r.createPfmDetail.id;
              this.$emit("add", this.editedItem);
            }
          }
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
